import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { ClipLoader } from "react-spinners";

function Whatsapp_Link_Spinner() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { uuid } = useParams();

  useEffect(() => {
    const fetchLink = async () => {
      try {
        const response = await axios.post(process.env.REACT_APP_APIURL + 'wp-link', {
          uuid,
        });
        window.location.href = response.data.link;
      } catch (err) {
        console.error("Error fetching link:", err);
        setError("Failed to fetch the link. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    if (uuid) {
      fetchLink();
    }
  }, [uuid]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <ClipLoader color="#A9A9A9" size={50} />
      </div>
    );
  }

  if (error) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          textAlign: "center",
          padding: "0 20px",
        }}
      >
        <p>{error}</p>
      </div>
    );
  }

  return null;
}

export default Whatsapp_Link_Spinner;
