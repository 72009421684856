import React, { useEffect } from "react";
import NavBar from "../Components/Navbar/NavBarSection/NavBar";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import ProtectedRoute from "../Components/Auth/ProtectedRoute";
import Developers from "../Components/Recruiter/Developers";
import ApplicationStatus from "../Components/Developer/ApplicationStatus";
import Calendly from "../Components/Admin/pages/calendly";
import UserHome from "../Pages/Home/UserHome";
import Careers from "../Pages/Careers/Careers";
import ApplyNow from "../Pages/ApplyNow/ApplyNow";
import PrivacyPolicy from "../Pages/PrivacyPolicy/PrivacyPolicy";
import NotFound from "../Components/NotFoundSection/NotFound";
import Whatsapp_Link_Spinner from "../Components/Whatsapp_Link_Spinner";

const Layout = () => {
    const { isLoggedIn, tabs } = useSelector(state => state.sessionHandler);

    const location = useLocation();
    // Check if the current path is "/a/:uid" (dynamic route)
    const isOnDynamicRoute = /^\/a\/[^/]+$/.test(location.pathname);

    // Hide or show the chatbot trigger based on the route
    useEffect(() => {
        let chatbotTrigger = document.getElementById('chatbot-trigger');
        let observer = null;

        // Function to update chatbot visibility
        const updateChatbotVisibility = () => {
            chatbotTrigger = document.getElementById('chatbot-trigger');
            if (chatbotTrigger) {
                // Use display: none to hide or block to show the chatbot
                chatbotTrigger.style.visibility = isOnDynamicRoute ? 'hidden' : 'visible';
                if (observer){
                    observer.disconnect();  // Disconnect observer once we've handled the element
                }
                
            }
        };

        // Handle the immediate visibility change
        updateChatbotVisibility();

        // Observe DOM mutations for the addition of the chatbot trigger element
        if (!chatbotTrigger) {
            observer = new MutationObserver(() => {
                updateChatbotVisibility();
            });

            observer.observe(document.body, { childList: true, subtree: true });
        }

        // Cleanup observer on unmount
        return () => {
            if (observer) observer.disconnect();
        };
    }, [location.pathname, isOnDynamicRoute]);

    return (
        <React.Fragment>
            {/* Render NavBar only if not on "/a/:uid" route */}
            {!isOnDynamicRoute && <NavBar />}
            {!isLoggedIn && (
                <Switch>
                    <Route exact path="/" component={UserHome} />
                    <Route exact path="/hire-ai-experts" component={UserHome} />
                    <Route exact path="/trusted-by-leading-brands" component={UserHome} />
                    <Route exact path="/hire-ex-experts" component={UserHome} />
                    <Route exact path="/hear-from-our-clients" component={UserHome} />
                    <Route exact path="/techstack" component={UserHome} />
                    <Route exact path="/areaexpertise" component={UserHome} />
                    <Route exact path="/guinness-world-records" component={UserHome} />
                    <Route exact path="/awards-and-recognition" component={UserHome} />
                    <Route exact path="/services-we-offer" component={UserHome} />
                    <Route exact path="/industry-verticals" component={UserHome} />
                    <Route exact path="/haveto" component={UserHome} />
                    <Route exact path="/fxchat" component={UserHome} />
                    <Route exact path="/aicoder" component={UserHome} />
                    <Route exact path="/aitutor" component={UserHome} />
                    <Route exact path="/contact" component={UserHome} />
                    <Route path="/careers" component={Careers} />
                    <Route path="/apply_now" component={ApplyNow} />
                    <Route path="/privacy-policy" component={PrivacyPolicy} />
                    <Route path="/a/:uuid" component={Whatsapp_Link_Spinner} />
                    <Route component={NotFound} />  
                </Switch>
            )}

            {isLoggedIn && (
                <React.Fragment>
                    <ProtectedRoute
                        path="/recruiter/developers"
                        component={Developers}
                    />

                    <ProtectedRoute
                        path="/developer/application-status"
                        component={ApplicationStatus}
                    />

                    <ProtectedRoute
                        path="/admin/calendly"
                        component={Calendly}
                    />

                    <Redirect exact from={"/"} to={tabs[0].path} />
                </React.Fragment>
            )}
        <div id="aimated-cursor">
            <div id="cursor">
            <div id="cursor-loader" />
            </div>
        </div>
    </React.Fragment>
  );
};

export default Layout;
