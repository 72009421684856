import React, { useState, useEffect, useRef } from "react";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { useTranslation } from "react-i18next";

const WhatsappPhoneNumberForm = ({
  phoneNumber,
  setPhoneNumber,
  error,
  inputProps,
  countrySelectorStyleProps,
  onBlur
}) => {
  const { t } = useTranslation();
  const phoneInputRef = useRef(null);



  // Initialize userCountry with a fallback (e.g., "us")
  const [userCountry, setUserCountry] = useState(null);

  // Fetch the user's country via IP address
  useEffect(() => {
    fetch("https://ipapi.co/json")
      .then((res) => res.json())
      .then((data) => {
        console.log(data, data.country_code);
        console.log("userCountry",userCountry);
        if (data.country_code) {
          // Convert country code to lowercase for PhoneInput
          setUserCountry(data.country_code.toLowerCase());
          console.log("userCountry",userCountry);
        }
      })
      .catch((err) => console.error("Error fetching IP info:", err));
  }, []);

  useEffect(() => {
    if (phoneInputRef.current && userCountry) {
      // This will only work if the library actually exposes .setCountry
      if (typeof phoneInputRef.current.setCountry === "function") {
        phoneInputRef.current.setCountry(userCountry);
      } else {
        console.warn(
          "Warning: phoneInputRef.current.setCountry is not a function. " +
            "Your library version may not support this."
        );
      }
    }
  }, [userCountry]);


  return (
    <div 
      className={`form-group${userCountry == null ? " hidden-country" : ""}`}
      style={{ 
        width: "100%"
      }}
    >
      <label htmlFor="whatsapp_phone_number_field" className="form-label">
        {t("connectnowmodel.phonenumber")}
      </label>
    

      <PhoneInput
        ref={phoneInputRef}
        value={phoneNumber}
        onChange={setPhoneNumber}
        forceDialCode={true}
        inputProps={{
          style: {
            width: "100%",
            fontSize: "16px",
            ...inputProps,
          },
        }}
        onBlur={(e) => {
          if (onBlur) onBlur(e);
        }}
        countrySelectorStyleProps={countrySelectorStyleProps}
      />
      {error && <small className="form-text text-danger">{error}</small>}
    </div>
  );
};

export default WhatsappPhoneNumberForm;
