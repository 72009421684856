import React from "react";
import { useTranslation } from "react-i18next";

const EmailForm = ({ email, setEmail, error, onBlur }) => {
  const {t} = useTranslation();
  return (
    <div className="form-group " style={{width:'100%'}}>
      <label
        htmlFor="email"
        className="form-label"
      >
        {t("hireaiexpertmodal.email")}
      </label>
      <input
        type="email"
        className="form-control form-inputs"
        id="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        onBlur={onBlur} 
      />
      {error && <small className="form-text text-danger">{error}</small>}
    </div>
  );
};

export default EmailForm;
