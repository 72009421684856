import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { RESET_AUTH_MESSAGES_AND_ERRORS } from "../../../redux/actions/actionTypes";
import { Modal } from "react-bootstrap";
import LoginForm from "./LoginForm";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import {Link} from 'react-router-dom';


const Login = ({ isSideBar }) => {
    const {t}=useTranslation()
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();

    const closeModal = () => {
        setShowModal(false);
        dispatch({
            type: RESET_AUTH_MESSAGES_AND_ERRORS
        });
    };

    return (
        <React.Fragment>
            {isSideBar ? (
                <li className="nav-item">
                    <Link
                        className="nav-link link"
                        onClick={() => {
                            setShowModal(true);
                            document.getElementById("btn_sideNavClose").click();
                            ReactGA.event({
                                category: "log_in_sidebar_click",
                                action: "log_in_sidebar_click",
                                label: "log_in_sidebar_click",
                                value: 1,
                            });
                        }}
                        to={"#login"}
                        id="btn_sideNavLogin"
                    >
                         {t("navbar.login")}
                    </Link>
                </li>
            ) : (
                <button
                    className="btn nav-btn-login"
                    onClick={() => setShowModal(true)}
                >
                    {t("navbar.login")}
                </button>
            )}
            <Modal
                show={showModal}
                onHide={closeModal}
                aria-labelledby={`developer-register-modal-title-vcenter`}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id={`developer-register-modal-title-vcenter`}>
                    {t("navbar.login")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LoginForm />
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};

export default Login;
