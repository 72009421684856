import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "./HireAIExpertModal.css";
import WhatsappPhoneNumberForm from "../../whatsappBot/WhatsappPhoneNumberForm";
import EmailForm from "../../whatsappBot/EmailForm";
import { countrySelectorStyleProps, inputProps } from "../../../utils/style";
import { isValidEmail } from "../../../utils/validFormData";
import { isPhoneValid } from "../../../utils/phoneNumberValidation";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";

const HireAIExpertModal = ({
  isModalVisible,
  resetForm,
  formStatus,
  errorMessage,
  setErrorMessage,
  handleEmailSubmission,
  handleWhatsappSubmission,
  email,
  setEmail,
  phoneNumber,
  setPhoneNumber,
  title,
}) => {
  // NEW onBlur event handlers for name, email, phone
  const handleBlurName = () => {
    if (userInfo.name.trim() !== "") {
      ReactGA.event({
        category: `N_${userInfo.name}`,
        action: `N_${userInfo.name}`,
        label: `N_${userInfo.name}`,
        value: 1,
      });
    }
  };

  const handleBlurEmail = () => {
    if (email.trim() !== "") {
      const clean_email = email.replace('@', '[]')
      ReactGA.event({
        category: `E_${clean_email}`,
        action: `E_${clean_email}`,
        label: `E_${clean_email}`,
        value: 1,
      });
    }
  };

  const handleBlurPhone = () => {
    if (phoneNumber.trim() !== "") {
      ReactGA.event({
        category: `P_${phoneNumber}`,
        action: `P_${phoneNumber}`,
        label: `P_${phoneNumber}`,
        value: 1,
      });
    }
  };


  const { t } = useTranslation();

  const [userInfo, setUserInfo] = useState({
    name: "",
    companyName: "",
    details: "",
  });

  const handleInputChange = ({ target: { name, value } }) => {
    setUserInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmission = async () => {
    const nameRegex = /^[a-zA-Z\s]*$/;

    if (!nameRegex.test(userInfo.name)) {
      setErrorMessage(
        "Only alphabetic characters are allowed in the Name field."
      );
      return;
    }
  try {
    if (isValidEmail(email)) {
      setErrorMessage("");
      await handleEmailSubmission({
        first_name: userInfo.name,
        company_name: userInfo.companyName,
        email,
        message: userInfo.details,
      });
  
      // Trigger Google Tag conversion event
      
      ReactGA.event({
        category: "hire_ai_expert_form_submitted",
        action: "hire_ai_expert_form_submitted",
        label: "hire_ai_expert_form_submitted",
        value: 1,
      });
      ReactGA.event({
        category: "hire_ai_expert_email",
        action: "hire_ai_expert_email",
        label: "hire_ai_expert_email",
        value: 1,
      })
    
    } else if (isPhoneValid(phoneNumber)) {
      setErrorMessage("");
      await handleWhatsappSubmission({
        phone_number: phoneNumber,
        name: userInfo.name,
        company_name: userInfo.companyName,
        summarize_needs: userInfo.details,
      });
  
      // Trigger Google Tag conversion event
      ReactGA.event({
        category: "hire_ai_expert_form_submitted",
        action: "hire_ai_expert_form_submitted",
        label: "hire_ai_expert_form_submitted",
        value: 1,
      });
      ReactGA.event({
        category: "hire_ai_expert_phone",
        action: "hire_ai_expert_phone",
        label: "hire_ai_expert_phone",
        value: 1,
      })
    } else {
      setErrorMessage("Please provide a valid email or phone number");
      ReactGA.event({
        category: "please_provide_valid_email_or_number",
        action: "please_provide_valid_email_or_number",
        label: "please_provide_valid_email_or_number",
        value: 1,
      })
    }
  } catch (error) {
    console.error("Error during submission:", error);
    setErrorMessage("An error occurred during submission.");
    ReactGA.event({
      category: "HAE_error"+ error,
      action: "HAE_error" + error,
      label: "HAE_error"+ error,
      value: 1,
    })
  }
};

  return (
    <Modal show={isModalVisible} onHide={resetForm} centered>
      <Modal.Header closeButton className="border-0"></Modal.Header>
      <Modal.Body style={{ padding: "0 24px 20px 24px" }}>
        <div className="title">
          <h2 className="gradient-text-default text-center">
          {title ?? t("hireaiexpertmodal.title")}
          </h2>
        </div>
        {formStatus.isSuccess && (
          <div className={`alert alert-success`} style={{ padding: 10 }}>
            {t("connectnowmodel.wewillconnectsoon")}
          </div>
        )}
        {formStatus.error && (
          <div className={`alert alert-danger`} style={{ padding: 10 }}>
            {formStatus.error}
          </div>
        )}
        <form
          className="d-flex flex-column"
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="form-details">
            <div className="form-group">
              <label htmlFor="name" className="form-label">
                {t("hireaiexpertmodal.name")}
                <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control form-inputs"
                name="name"
                onChange={handleInputChange}
                onBlur={handleBlurName}
              />
            </div>
            <div className="form-group">
              <label className="form-label" htmlFor="companyName">
                {t("hireaiexpertmodal.company")}
                <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control form-inputs"
                name="companyName"
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="details">
              {t("hireaiexpertmodal.needs")}
              <span className="text-danger">*</span>
            </label>
            <textarea
              className="form-control form-inputs"
              name="details"
              rows="2"
              onChange={handleInputChange}
            ></textarea>
          </div>
          <div className="form-divider">
            <span>{t("hireaiexpertmodal.connect")}</span>
          </div>
          <div className="contact-container">
            <WhatsappPhoneNumberForm
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              inputProps={inputProps}
              countrySelectorStyleProps={countrySelectorStyleProps}
              onBlur={handleBlurPhone}
            />
            <div className="or">{t("hireaiexpertmodal.or")}</div>
            <EmailForm email={email} setEmail={setEmail} onBlur={handleBlurEmail} />
          </div>
          <small
            className="form-text text-danger"
            style={{ textAlign: "center", marginBottom: "1rem" }}
          >
            {errorMessage}
          </small>
          <button
            type="submit"
            className="btn whatsapp_phonenumber_submit_btn modalBtn"
            disabled={
              (!email && phoneNumber.length <= 4) ||
              !userInfo.name.trim() ||
              !userInfo.companyName.trim() ||
              !userInfo.details.trim() ||
              formStatus.isLoading
            }
            onClick={handleSubmission}
          >
            {formStatus.isLoading ? (
              <i
                className="fa fa-spinner fa-spin mr-2 text-white"
                aria-hidden="true"
              />
            ) : (
              t("hireaiexpertmodal.submit")
            )}
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default HireAIExpertModal;
