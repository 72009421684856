import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../../../redux/actions/sessionActions";
import { MAX_FILE_UPLOAD_SIZE } from "../../../utils/constants";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";

const DeveloperRegistrationForm = () => {
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const {
        handleSubmit,
        control,
        errors,
        watch,
        getValues,
        reset
    } = useForm();
    const dispatch = useDispatch();
    const {
        errors: registerErrors,
        registerSuccessMessage,
        loaders
    } = useSelector(state => state.sessionHandler);
    const resumeFile = watch("resume_file") || null;

    useEffect(() => {
        registerSuccessMessage && reset();
    }, [registerSuccessMessage]);

    const onSubmit = data => {
        const formData = new FormData();
        for (const key of Object.keys(data)) {
            formData.append(key, data[key]);
        }
        formData.append("user_type", "developer");
        dispatch(register(formData));
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <form
            className="default-form carrers-form"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className={"row"}>
                <div className="col-sm-12">
                    {registerSuccessMessage !== null && (
                        <div
                            className={`alert alert-success`}
                            style={{ padding: 10 }}
                        >
                            {registerSuccessMessage}
                        </div>
                    )}
                    {registerErrors.register !== null && (
                        <div
                            className={`alert alert-danger`}
                            style={{ padding: 10 }}
                        >
                            {registerErrors.register}
                        </div>
                    )}
                </div>

                <div className="col-md-6 col-sm-12 form-p-0" id="form-div-main">
                    <div className={"form-group"}>
                        <label htmlFor="first_name" className="required">
                           {t("applynow.firstname")}
                        </label>
                        <Controller
                            as={<input className={"form-control"} placeholder="e.g. John" />}
                            id={"first_name"}
                            name={"first_name"}
                            control={control}
                            defaultValue={""}
                            rules={{ required: true }}
                            
                        />
                        <small className={"form-text text-danger"}>
                            {errors.first_name && (
                                <span>First name is required!</span>
                            )}
                        </small>
                    </div>
                </div>

                <div className="col-md-6 col-sm-12 form-p-0" id="form-div-main">
                    <div className={"form-group"}>
                        <label htmlFor="last_name" className="required">
                        {t("applynow.lastname")}
                        </label>
                        <Controller
                            as={<input className={"form-control"} placeholder="e.g. Doe"/>}
                            id={"last_name"}
                            name={"last_name"}
                            control={control}
                            defaultValue={""}
                            rules={{ required: true }}
                        />
                        <small className={"form-text text-danger"}>
                            {errors.last_name && (
                                <span>Last name is required!</span>
                            )}
                        </small>
                    </div>
                </div>

                <div className="col-md-12 col-sm-12 form-p-0">
                    <div className={"form-group"}>
                        <label htmlFor="email" className="required">
                        {t("applynow.email")}
                        </label>
                        <Controller
                            as={<input className={"form-control"}  placeholder="e.g. john.doe@example.com"/>}
                            id={"email"}
                            name={"email"}
                            control={control}
                            defaultValue={""}
                            rules={{
                                required: true,
                                pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            }}
                        />
                        <small className={"form-text text-danger"}>
                            {errors.email && (
                                <React.Fragment>
                                    {errors.email.type === "required" && (
                                        <span>Email is required!</span>
                                    )}
                                    {errors.email.type === "pattern" && (
                                        <span>Email is not valid</span>
                                    )}
                                </React.Fragment>
                            )}
                        </small>
                    </div>
                </div>

                <div className="col-md-12 col-sm-12 form-p-0">
                    <div className={"form-group"}>
                        <label htmlFor="phone" className="required">
                        {t("applynow.phone")}
                        </label>
                        <Controller
                            as={<input className={"form-control"} placeholder="e.g. +1 123-456-7890" />}
                            id={"phone"}
                            name={"phone"}
                            control={control}
                            defaultValue={""}
                            rules={{
                                required: true,
                                pattern: /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g
                            }}
                        />
                        <small className={"form-text text-danger"}>
                            {errors.phone && (
                                <React.Fragment>
                                    {errors.phone.type === "required" && (
                                        <span>Phone number is required!</span>
                                    )}
                                    {errors.phone.type === "pattern" && (
                                        <span>Phone number is not valid</span>
                                    )}
                                </React.Fragment>
                            )}
                        </small>
                    </div>
                </div>

                <div className="col-md-12 col-sm-12 form-p-0">
                    <div className={"form-group"}>
                        <label htmlFor="position" className="required">
                        {t("applynow.position")}
                        </label>
                        <Controller
                            as={<input className={"form-control"} placeholder="e.g. Front-End Developer" />}
                            id={"position"}
                            name={"position"}
                            control={control}
                            defaultValue={""}
                            rules={{
                                required: true,
                            }}
                        />
                        <small className={"form-text text-danger"}>
                            {errors.position && (
                                <React.Fragment>
                                    {errors.position.type === "required" && (
                                        <span>Position is required!</span>
                                    )}
                                </React.Fragment>
                            )}
                        </small>
                    </div>
                </div>

                <div className="col-md-12 col-sm-12 form-p-0">
                    <label style={{paddingBottom:"10px"}}>{t("applynow.exp")}</label>
                </div>

                <div className="col-md-6 col-sm-12 form-p-0" id="form-div-main">
                    <div className={"form-group"}>
                        <label htmlFor="year" className="required">
                        {t("applynow.year")}
                        </label>
                        <Controller
                            as={<input className={"form-control"} />}
                            id={"year"}
                            name={"year"}
                            control={control}
                            defaultValue={"01"}
                            rules={{
                                required: true,
                                pattern: /^\d{2}$/
                            }}
                        />
                        {errors.year && (
                            <small className={"form-text text-danger"}>
                                {errors.year && (
                                    <span>Year is required!</span>
                                )}
                                {errors.year.type === "pattern" && (
                                    <span>Enter valid years</span>
                                )}
                            </small>
                        )}
                    </div>
                </div>

                <div className="col-md-6 col-sm-12 form-p-0" id="form-div-main">

                    <div className={"form-group"}>
                        <label htmlFor="month" className="required">
                        {t("applynow.month")}
                        </label>
                        <Controller
                            as={<input className={"form-control"} />}
                            id={"month"}
                            name={"month"}
                            control={control}
                            defaultValue={"00"}
                            rules={{
                                required: true,
                                pattern: /^(0[0-9]|1[012])$/
                            }}
                        />
                        {errors.month && (
                            <small className={"form-text text-danger"}>
                                {errors.month && (
                                    <span>Month is required!</span>
                                )}
                                {errors.month.type === "pattern" && (
                                    <span>Enter only Month 00-12</span>
                                )}
                            </small>
                        )}
                    </div>
                </div>

                <div className="col-md-12 col-sm-12 form-p-0">
                    <div className={"form-group"}>
                        <label htmlFor="password" className="required">
                        {t("applynow.pass")}
                        </label>
                        <Controller
                            as={
                                <div className="input-group">
                                <input
                                    type={showPassword ? "text" : "password"}
                                    className="form-control"
                                />
                                <div className="input-group-append">
                                    <button
                                        type="button"
                                        style={{
                                            border:"2px solid #5928d1 ",
                                            color:"#5928d1",
                                            background:"#fff",
                                            cursor:"pointer"
                                        }}
                                        onClick={toggleShowPassword}
                                    >
                                        {showPassword ? "Hide" : "Show"}
                                    </button>
                                </div>
                            </div>
                            }
                            id={"password"}
                            name={"password"}
                            control={control}
                            defaultValue={""}
                            rules={{
                                required: true,
                                minLength: 8,
                                pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$/
                            }}
                        />
                        <small className={"form-text text-danger"}>
                            {errors.password && (
                                <React.Fragment>
                                    {errors.password.type === "required" && (
                                        <span>Password is required!</span>
                                    )}
                                    {errors.password.type === "minLength" && (
                                        <span>
                                            Password length should be at least 8
                                            characters
                                        </span>
                                    )}
                                    {errors.password.type === "pattern" && (
                                        <span>
                                            Password should contain a-z,A-Z,0-9
                                            and any special character
                                        </span>
                                    )}
                                </React.Fragment>
                            )}
                        </small>
                    </div>
                </div>

                <div className="col-md-12 col-sm-12 form-p-0">
                    <div className={"form-group"}>
                        <label htmlFor="confirm_password" className="required">
                        {t("applynow.cnfpass")}
                        </label>
                        <Controller
                            as={
                                <input
                                    type={"password"}
                                    className={"form-control"}
                                />
                            }
                            id={"confirm_password"}
                            name={"confirm_password"}
                            control={control}
                            defaultValue={""}
                            rules={{
                                validate: val => {
                                    const { password } = getValues();
                                    return (
                                        password === val ||
                                        "Confirm password doesn't match"
                                    );
                                }
                            }}
                        />
                        <small className={"form-text text-danger"}>
                            {errors.confirm_password && (
                                <React.Fragment>
                                    {errors.confirm_password.type ===
                                        "validate" && (
                                            <span>
                                                {errors.confirm_password.message}
                                            </span>
                                        )}
                                </React.Fragment>
                            )}
                        </small>
                    </div>
                </div>

                <div className="col-md-12 col-sm-12 form-p-0">
                    <div className={"form-group"}>
                        <label htmlFor="resume_file" className="required">
                        {t("applynow.upldrsm")}&nbsp;
                            <span style={{ fontSize: 9 }}>(pdf, doc only)</span>
                        </label>
                        <div className="dropzone-wrapper">
                            <div className="dropzone-desc">
                                {resumeFile === null ? (
                                    <React.Fragment>
                                        <i
                                            style={{ color: "#6d6d6d" }}
                                            className="fa fa-upload"
                                        />
                                        <p style={{ fontSize: 13 }}>
                                        {t("applynow.choose")}
                                        </p>
                                    </React.Fragment>
                                ) : (
                                    <div style={{ color: "#6d6d6d" }}>
                                        <i
                                            style={{ fontSize: 16 }}
                                            className={"fa fa-file mr-2"}
                                        />
                                        <span style={{ fontSize: 14 }}>
                                            {resumeFile.name.length >= 25
                                                ? resumeFile.name.slice(0, 23) +
                                                "..."
                                                : resumeFile.name}
                                        </span>
                                    </div>
                                )}
                            </div>
                            <Controller
                                id={"resume_file"}
                                name={"resume_file"}
                                control={control}
                                defaultValue={""}
                                rules={{
                                    required: true,
                                    validate: {
                                        maxSize: file => {
                                            return (
                                                file.size <=
                                                MAX_FILE_UPLOAD_SIZE *
                                                1024 *
                                                1024 ||
                                                `File size should be less then ${MAX_FILE_UPLOAD_SIZE} MB`
                                            );
                                        }
                                    }
                                }}
                                render={props => (
                                    <input
                                        type="file"
                                        name="resume_file"
                                        accept=".doc,.docx,.pdf"
                                        className="dropzone"
                                        onChange={e =>
                                            props.onChange(e.target.files[0])
                                        }
                                    />
                                )}
                            />
                        </div>
                        <small className={"form-text text-danger"}>
                            {errors.resume_file && (
                                <React.Fragment>
                                    {errors.resume_file.type === "required" && (
                                        <span>Please upload your resume</span>
                                    )}
                                    {errors.resume_file.type === "maxSize" && (
                                        <span>
                                            {errors.resume_file.message}
                                        </span>
                                    )}
                                </React.Fragment>
                            )}
                        </small>
                    </div>
                </div>

                <div className="col-md-12 col-sm-12">
                    <div className={"form-group"}>
                        <div className={"d-flex align-items-center"}>
                            <Controller
                                id={"terms_accepted"}
                                name={"terms_accepted"}
                                control={control}
                                defaultValue={false}
                                rules={{ required: true }}
                                render={props => (
                                    <input
                                        type={"checkbox"}
                                        checked={props.value}
                                        onChange={e =>
                                            props.onChange(e.target.checked)
                                        }
                                        id="terms_accepted"
                                    />
                                )}
                            />
                            <label
                                htmlFor="terms_accepted"
                                className="ml-2 required"
                            >
                                <span>{t("applynow.accept")}&nbsp;</span>
                                <a onClick={() => {
                                    ReactGA.event({
                                        category: "apply_now_accept_click",
                                        action: "apply_now_accept_click",
                                        label: "apply_now_accept_click",
                                        value: 1,
                                      });
                                    setShowModal(true)
                                }}>
                                {t("applynow.tandc")}
                                </a>
                            </label>
                        </div>
                        <small className={"form-text text-danger"}>
                            {errors.terms_accepted && (
                                <span>Please accept terms and conditions</span>
                            )}
                        </small>
                    </div>
                </div>
                <div>
                    <Modal
                        show={showModal}
                        onHide={() => setShowModal(false)}
                        aria-labelledby={`developer-register-modal-title-vcenter`}
                        size="lg"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title
                                id={`developer-register-modal-title-vcenter`}
                            >
                               {t("applynow.title")}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body
                            style={{
                                maxHeight: "calc(100vh - 210px)",
                                overflowY: "auto",
                                textAlign: "left"
                            }}
                        >
                            <p>{t("applynow.effective")}</p>
                            <p>{t("applynow.desc1")}
                            </p>
                            <p>
                            {t("applynow.desc2")}
                            </p>
                            <p style={{ fontWeight: 700 }}>{t("applynow.rights")} </p>

                            <p>
                                &#x2022; {t("applynow.desc3")}{" "}
                            </p>
                            <p>
                                &#x2022; {t("applynow.desc4")}{" "}
                            </p>
                            <p>
                                &#x2022;{t("applynow.desc5")}{" "}
                            </p>
                            <p>
                                &#x2022; {t("applynow.desc6")}{" "}
                            </p>
                            <p>
                                &#x2022; {t("applynow.desc7")}
                            </p>
                            <p>
                                &#x2022;{t("applynow.desc8")}
                            </p>

                            <p style={{ fontWeight: 700 }}>{t("applynow.accounts")} </p>

                            <p>
                                &#x2022; {t("applynow.desc9")}{" "}
                            </p>
                            <p>
                                &#x2022; {t("applynow.desc10")}{" "}
                            </p>
                            <p>
                                &#x2022; {t("applynow.desc11")}
                            </p>
                            <p>
                                &#x2022; {t("applynow.desc12")}
                            </p>

                            <p style={{ fontWeight: 700 }}>{t("applynow.user")} </p>
                            <p>
                                &#x2022; {t("applynow.desc13")}
                            </p>
                            <p>
                                &#x2022; {t("applynow.desc14")}
                            </p>

                            <p style={{ fontWeight: 700 }}>
                            {t("applynow.interaction")}{" "}
                            </p>
                            <p>
                                &#x2022; {t("applynow.desc15")}
                            </p>

                            <p style={{ fontWeight: 700 }}>{t("applynow.acceptable")} </p>
                            <p>
                            {t("applynow.desc16")}
                            </p>
                            <p>
                                &#x2022; {t("applynow.desc17")}
                            </p>
                            <p>
                                &#x2022;{t("applynow.desc18")}
                            </p>
                            <p>
                                &#x2022; {t("applynow.desc19")}
                            </p>
                            <p>
                                &#x2022; {t("applynow.desc20")}
                            </p>
                            <p>
                                &#x2022; {t("applynow.desc21")}
                            </p>

                            <p style={{ fontWeight: 700 }}>
                            {t("applynow.changes")}{" "}
                            </p>
                            <p>
                                &#x2022; {t("applynow.desc22")}
                            </p>
                            <p>
                                &#x2022; {t("applynow.desc23")}
                            </p>
                            <p>
                                &#x2022; {t("applynow.desc24")}
                            </p>

                            <p style={{ fontWeight: 700 }}>
                            {t("applynow.intellectual")}{" "}
                            </p>
                            <p>
                                &#x2022;{t("applynow.desc25")}{" "}
                            </p>
                            <p>
                                &#x2022; {t("applynow.desc26")}
                            </p>

                            <p style={{ fontWeight: 700 }}>
                            {t("applynow.limitation")}{" "}
                            </p>
                            <p>
                            {t("applynow.desc27")}
                            </p>

                            <p style={{ fontWeight: 700 }}>{t("applynow.disclaimer")}</p>
                            <p>
                            {t("applynow.desc28")}
                            </p>
                        </Modal.Body>
                    </Modal>
                </div>

                <div className="col-sm-12 form-p-0">
                    <button
                        type="submit"
                        disabled={loaders.register ? true : ""}
                        className="btn btn-carrer"
                        id="signup_submit_btn"
                    >
                        {loaders.register && (
                            <i
                                className="fa fa-spinner fa-spin mr-2"
                                aria-hidden="true"
                            />
                        )}
                        <span>
                            {loaders.register ? "Register..." : "Apply"}
                        </span>
                    </button>
                </div>
            </div>
        </form>
    );
};

export default DeveloperRegistrationForm;
