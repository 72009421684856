import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ApplyForJobModal from "../Modals/ApplyForJobModal";
import HireAIExpertModal from "../Modals/HireAIExpertModal/HireAIExpertModal";
import SuccessModal from "./SucceesModal";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import { useCalendlyEventListener, PopupButton } from "react-calendly";


const ConnectNowModal = () => {
  useCalendlyEventListener({
    onProfilePageViewed: () => {
      ReactGA.event({
        category: "cal_profile_page_viewed",
        action: "cal_profile_page_viewed",
        label: "cal_profile_page_viewed",
        value: 1,
      });
    },
    onDateAndTimeSelected: () => {
      ReactGA.event({
        category: "cal_date_time_selected",
        action: "cal_date_time_selected",
        label: "cal_date_time_selected",
        value: 1,
      });
    },
    onEventTypeViewed: () => {
      ReactGA.event({
        category: "cal_event_type_viewed",
        action: "cal_event_type_viewed",
        label: "cal_event_type_viewed",
        value: 1,
      });
    },
    onEventScheduled: (e) => {
      ReactGA.event({
        category: "hire_ai_expert_form_submitted",
        action: "hire_ai_expert_form_submitted",
        label: "hire_ai_expert_form_submitted",
        value: 1,
      });
      ReactGA.event({
        category: "cal_event_scheduled",
        action: "cal_event_scheduled",
        label: "cal_event_scheduled",
        value: 1,
      });
    },
    onPageHeightResize: (e) => {
      ReactGA.event({
        category: "cal_schedule_meet_clicked",
        action: "cal_schedule_meet_clicked",
        label: "cal_schedule_meet_clicked",
        value: 1,
      });
    },
  });

  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUsingEmailForm, setIsUsingEmailForm] = useState(false);
  const [currentCategory, setCurrentCategory] = useState("collaborate");
  const [errorMessage, setErrorMessage] = useState(null);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [formStatus, setFormStatus] = useState({
    isLoading: false,
    isSuccess: false,
    error: null,
  });
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);

  const handleSuccess = () => {
    setIsSuccessModalVisible(true);
  };
  const handleCloseSuccessModal = () => {
    setIsSuccessModalVisible(false);
  };

  const resetForm = () => {
    setIsModalVisible(false);
    setErrorMessage(null);
    setIsUsingEmailForm(false);
    setPhoneNumber("");
    setEmail("");
    setFormStatus({ isLoading: false, isSuccess: false, error: null });
  };

  const submitWhatsappMessage = async (data) => {
    try {
      setFormStatus({ ...formStatus, isLoading: true });
      await axios.post(`${process.env.REACT_APP_WHATSAPP_API_CALL}send`, {
        ...data,
        category: currentCategory,
      });
      setFormStatus({ isLoading: false, isSuccess: true });
      resetForm();
      handleSuccess();
    } catch (err) {
      setFormStatus({ isLoading: false, error: "Something went wrong!" });
    }
  };

  const submitEmailForm = async (data) => {
    try {
      setFormStatus({ ...formStatus, isLoading: true });
      await axios.post(
        `${process.env.REACT_APP_APIURL}contact`,
        { ...data, category: currentCategory },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      setFormStatus({ isLoading: false, isSuccess: true });
      resetForm();
      handleSuccess();
    } catch (err) {
      setFormStatus({ isLoading: false, error: "Something went wrong!" });
    }
  };
  useEffect(() => {
    
    if (formStatus.error) {
      
      setTimeout(() => setFormStatus({ ...formStatus, error: null }), 3000);
    }
  }, [formStatus]);

  // 1) Check on initial load if the path is /hire-ai-experts.
  //    If yes, open the modal in "collaborate" mode.
  useEffect(() => {
    // If you are not using React Router, you can directly check:
    if (window.location.pathname === "/hire-ai-experts") {
      setIsModalVisible(true);
      setCurrentCategory("collaborate");
    }
    // If using React Router <BrowserRouter>, you could also do:
    // const location = useLocation();
    // if (location.pathname === "/hire-ai-experts") {
    //   setIsModalVisible(true);
    //   setCurrentCategory("collaborate");
    // }
  }, []);

  



  return (
    <>
      <div
        className="landing-btn"
        style={{ display: "flex", alignItems: "center" }}
      >
        <button
          id="hireai-expert-btn"
          className="whatsapp_color_replace1 whatsapp_connect_now_btn"
          onClick={() => {
            window.history.pushState({}, "", "/hire-ai-experts");
            ReactGA.event({
              category: "hire_ai_exports_hero_click",
              action: "hire_ai_exports_hero_click",
              label: "hire_ai_exports_hero_click",
              value: 1,
            });
            setIsModalVisible(true);
            setCurrentCategory("collaborate");
          }}
        >
          {t("home.connectnow1")}
        </button>
        {/* <button
          id="schedule-meeting-btn"
          className="whatsapp_color_replace1 whatsapp_connect_now_btn"
          onClick={openCalendlyPopup}
        >
          Schedule a Meet
        </button> */}
        <PopupButton
          url="https://calendly.com/hemenashodia/30min"
          className="whatsapp_color_replace1 whatsapp_connect_now_btn schedule-meeting-btn"
          /*
          * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
          * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
          */
          rootElement={document.getElementById("root")}
          text="Schedule a Meet"
        />

      </div>
      <p className="text-look text-white apply-now-text">
        {t("hirenow.lookingforjob")}{" "}
        <span
          className="text-white link-job"
          style={{ cursor: "pointer" }}
          onClick={() => {
            ReactGA.event({
              category: "apply_for_job_hero_click",
              action: "apply_for_job_hero_click",
              label: "apply_for_job_hero_click",
              value: 1,
            });
            setIsModalVisible(true);
            setCurrentCategory("career");
          }}
        >
          {t("home.apply")}
        </span>
      </p>
      {currentCategory === "career" ? (
        <ApplyForJobModal
          isModalVisible={isModalVisible}
          resetForm={resetForm}
          formStatus={formStatus}
          isUsingEmailForm={isUsingEmailForm}
          errorMessage={errorMessage}
          setIsUsingEmailForm={setIsUsingEmailForm}
          setErrorMessage={setErrorMessage}
          handleEmailSubmission={submitEmailForm}
          handleWhatsappSubmission={submitWhatsappMessage}
          email={email}
          setEmail={setEmail}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
        />
      ) : (
        <HireAIExpertModal
          isModalVisible={isModalVisible}
          resetForm={resetForm}
          formStatus={formStatus}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          handleEmailSubmission={submitEmailForm}
          handleWhatsappSubmission={submitWhatsappMessage}
          email={email}
          setEmail={setEmail}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
        />
      )}
      {isSuccessModalVisible && (
        <SuccessModal
          SuccessModalVisible={isSuccessModalVisible}
          onclose={handleCloseSuccessModal}
        />
      )}
    </>
  );
};

export default ConnectNowModal;
